import CustomIcons from '@/components/icons/CustomIcons'
import Octicons from '@primer/octicons'
import { h } from 'vue'

function hSvg(name) {
  const octicon = Octicons[name]?.heights[16]
  if (octicon)
    return h('svg', { viewBox: octicon.options.viewBox, innerHTML: octicon.path })
  const customIcon = CustomIcons[name]
  if (customIcon)
    return h('svg', { innerHTML: customIcon })
  return h('svg', { innerHTML: '' })
}

export default function Icon(props, { attrs }) {
  return h(
    'div',
    {
      class: [
        'icon',
        props.color ?? 'inherit',
        {
          'flip-horizontal': props.flip === 'horizontal',
          'flip-vertical': props.flip === 'vertical',
          'rotate': !!props.rotate && props.rotate !== '0',
          'spin': attrs.spin === '' || props.spin,
        },
      ],
      style: {
        '--scale': props.scale,
        '--rotate': props.rotate,
      },
    },
    [hSvg(props.name)],
  )
}
