<script setup>
import { useStorage } from '@vueuse/core'

const dark = useStorage('picsellia-theme', 'light')
</script>

<template>
  <div class="fallback-layout" :class="[dark]">
    <slot />
  </div>
</template>

<style scoped>
.fallback-layout {
  height: 100%;
  width: 100%;
}
</style>
