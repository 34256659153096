<script>
import Turnstile from '@/components/auth/Turnstile.vue'
import IAsyncForm from '@/components/form/IAsyncForm.vue'
import Icon from '@/components/icons/Icon'
import useAuth from '@/composables/useAuth.js'
import AuthApi from '@/js/api/AuthApi.js'
import IButton from '@inkline/inkline/components/IButton/IButton.vue'
import IForm from '@inkline/inkline/components/IForm/IForm.vue'
import IFormError from '@inkline/inkline/components/IFormError/IFormError.vue'
import IFormGroup from '@inkline/inkline/components/IFormGroup/IFormGroup.vue'
import IFormLabel from '@inkline/inkline/components/IFormLabel/IFormLabel.vue'
import IInput from '@inkline/inkline/components/IInput/IInput.vue'
import ILoader from '@inkline/inkline/components/ILoader/ILoader.vue'
import { computed, ref } from 'vue'

const page = {
  components: {
    IAsyncForm,
    Icon,
    Turnstile,
    IButton,
    ILoader,
    IInput,
    IForm,
    IFormGroup,
    IFormLabel,
    IFormError,
  },
  setup() {
    const schema = {
      username: {
        value: '',
        validators: [{ name: 'required', message: 'Email or username is required' }],
      },
      password: {
        value: '',
        validators: [{ name: 'required', message: 'Password is required' }],
      },
      captcha_token: {
        value: '',
        validators: [{ name: 'required', message: 'Captcha is required' }],
      },
    }

    const error = ref('')
    const pendingEmailConfirmation = ref(false)
    const siteKey = ref(process.env.TURNSTILE_SITE_KEY)
    const turnstileRef = ref(null)

    const { csrfToken } = useAuth()
    const signinWithGoogle = computed(() => `${location.origin}/_allauth/browser/v1/auth/provider/redirect`)
    const googleCallback = computed(() => `${location.origin}/accounts/google/login/callback/`)

    async function signin(payload) {
      error.value = null
      try {
        await AuthApi.login(payload)
        return { redirectUrl: '/' }
      }
      catch (e) {
        if (e?.status === 400) {
          error.value = e.body.errors[0].message
        }
        else if (e?.status === 401) {
          pendingEmailConfirmation.value = true
          return {}
        }
        else if (e?.status === 409) {
          // Already logged in
          return { redirectUrl: '/' }
        }
        console.error(e)
        reset()
        return Promise.reject({})
      }
    }

    function reset() {
      turnstileRef.value.reset()
      schema.password.value = ''
    }

    return {
      schema,
      error,
      pendingEmailConfirmation,
      siteKey,
      turnstileRef,
      csrfToken,
      signinWithGoogle,
      googleCallback,
      signin,
    }
  },
}

window.pageComponent = page
export default page
</script>

<template>
  <div class="auth-form">
    <IAsyncForm :schema="schema" :action="signin" :readonly="pendingEmailConfirmation" submit-label="Sign In" dirty>
      <template #default>
        <header>
          <Icon class="logo" name="brand-picsellia" />
          <h1>{{ pendingEmailConfirmation ? "Email Verification" : "Login with" }}</h1>
        </header>

        <p v-if="pendingEmailConfirmation" class="confirmation">
          We have sent you an activation link by mail. <br>
          Please check your spams/promotions if you don't find it in your inbox.
        </p>
        <template v-else>
          <IFormGroup>
            <IFormLabel class="label">
              Email address or username
            </IFormLabel>
            <IInput class="input" name="username" type="username" />
            <IFormError for="username" />
          </IFormGroup>

          <IFormGroup>
            <IFormLabel class="label">
              Password
            </IFormLabel>
            <IInput class="input" name="password" type="password" />
            <IFormError for="password" />
          </IFormGroup>

          <IFormGroup>
            <Turnstile ref="turnstileRef" name="captcha_token" :site-key="siteKey" />
            <IFormError for="captcha_token" />
          </IFormGroup>

          <div v-if="error" class="error">
            {{ error }}
          </div>
        </template>
      </template>
    </IAsyncForm>

    <form v-if="!pendingEmailConfirmation" :action="signinWithGoogle" method="post" class="google-form">
      <input type="hidden" name="csrfmiddlewaretoken" :value="csrfToken">
      <input type="hidden" name="provider" value="google">
      <input type="hidden" name="callback_url" :value="googleCallback">
      <input type="hidden" name="process" value="login">
      <IButton type="submit" class="google-button">
        <template #icon>
          <iconify-icon icon="flat-color-icons:google" />
        </template>
        Sign in with Google
      </IButton>
    </form>

    <div v-if="!pendingEmailConfirmation" class="footer">
      <p class="demo-title">
        <span>Don't have an account yet?&nbsp;</span>
        <a href="/signup" class="demo-title">Sign up now</a>
      </p>
      <p class="demo-title">
        <a href="/forgot-password" class="forget-password-title">Forgot password?</a>
      </p>
    </div>
    <div v-else class="footer">
      <a href="/signin" type="button" class="button -light -md">Back to Sign in</a>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/styles/authPage.scss";

.demo-title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
  font-weight: 700 !important;
}

.forget-password-title {
  text-align: center;
}

.google-form,
.google-button {
  width: 100%;
}
</style>
